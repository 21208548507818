import { AccessModeEnum, BaseNetworkData } from "@/includes/types/networks";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class NetworksHelperMixin extends Vue {

  gotoNetworkTrigger(actionType: 'new' | 'edit' | 'copy', triggerId?: string, routerType: 'push' | 'replace' = "push") {
    const routeConfig = {
      name: 'NetworkTrigger',
      params: {
        ...triggerId ? { triggerId } : undefined,
        actionType
      }
    }

    if (routerType === 'push') {
      this.$router.push(routeConfig)
    } else {
      this.$router.replace(routeConfig)
    }
  }

  gotoNetDashboard(netId?: BaseNetworkData['id']) {
    this.$router.push({
      name: "NetworkDashboard",
      params: {
        ...netId ? { networkId: netId.toString() } : {}
      }
    })
  }

  gotoNetworksList() {
    this.$router.push({ name: 'NetworksList' })
  }

  gotoNetworkModule(netId: BaseNetworkData['id'], guidOrType: string) {
    this.$router.push({
      name: "NetworkCurrentModule",
      params: {
        networkId: netId.toString(),
        moduleGuidOrType: guidOrType,
      }
    })
  }

  get accessModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('chat_network_access_mode_all').toString(),
        value: AccessModeEnum.All
      },
      {
        label: this.$t('chat_network_access_mode_owner').toString(),
        value: AccessModeEnum.Owner
      },
      {
        label: this.$t('chat_network_access_mode_any').toString(),
        value: AccessModeEnum.Any
      },
    ]
  }
}
